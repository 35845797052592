import React from 'react';
import "./App.css";
import Cekici from "./components/Cekici";

export default function App() {
  return (
    <div className="App">
      <Cekici />
    </div>
  );
}
